import React from "react";
import { SEO, PageLayout, ContactForm } from "@bluefin/components";
import { Grid, Header, Image, p } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class BookYourBandPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={false}
          subfooter={false}
          className={"bands-page custom-page"}
        >
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={10}>
              <Header as={"h1"}>LOCAL BAND BOOKING</Header>
              <p className={"italic-case"}>
                Thank you for your interest in playing at Bennie's Pizza Pub, we
                typically hire solo, duo and trio acts Thursday-Saturday and
                occasionally bigger bands for events.
              </p>
              <p>
                <Image
                  size={"large"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/band.jpg"
                  }
                />
              </p>
              <p>
                We require all musicians to fill out a Federal W-9 to perform,
                and we pay cash or check the night of the performance. We do not
                offer complimentary food or beverages, and we do not allow
                solicitation of alcoholic beverages from our patrons. You will
                also be required to sign a professionalism agreement upon
                setting a date
              </p>
              <Header as={"h1"}>HOW TO BOOK</Header>
              <p className={"rm-margin-bottom"}>
                If you wish to proceed with booking at Bennie's, then please
                fill out the form below and someone will contact you within a
                few days.
              </p>
              <p className={"rm-margin-top"}>
                *Filling out this form in no way guarantees you a booking.
              </p>
              <p>
                <Image
                  size={"medium"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/Bands_-_Content_Image_2.jpg"
                  }
                />
              </p>
              <p className={"italic-case"}>
                For consideration please fill in the form below and you will
                receive a response within the week!
              </p>
            </Grid.Column>
            <Grid.Column textAlign={"left"} width={"8"}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  phone,
                  bandName,
                  artistNumber,
                  hourlyPrice,
                  music,
                  link,
                }) =>
                  `Someone has reached out to you via your website band form: Name: ${name}, Email: ${email}, Phone: ${phone}, Band Name: ${bandName}, How Many Artists: ${artistNumber}, How Much Per Hour: ${hourlyPrice}, Style of Music: ${music}, Links to Your Music or Performances: ${link}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "bandName",
                    displayLabel: "Name of Band",
                    placeholder: "Name of Band",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "artistNumber",
                    displayLabel: "How Many Artists",
                    placeholder: "How Many Artists",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "hourlyPrice",
                    displayLabel: "How Much Per Hour",
                    placeholder: "How Much Per Hour",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "music",
                    displayLabel: "Style of Music",
                    placeholder: "Style of Music",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "link",
                    displayLabel: "Links to Your Music or Performances",
                    placeholder: "Links to Your Music or Performances",
                    type: "input",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                destinationEmail={"bands@benniespizzapub.net"}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Book Your Band At Bennie's" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
